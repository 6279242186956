<template>
  <div class="pa-5">
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-img
          class="mx-2"
          src="../assets/socialgeniuslogo.png"
          max-height="40"
          max-width="40"
          contain
        ></v-img>
        <v-toolbar-title
          >Social Genius API: HackMakeMod Marquee Project Setup</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-avatar size="48">
          <img
            src="https://yt3.ggpht.com/O3k22rMrvX6e9nb3b5QJw-NFhkznVMqEoUyyPETS1bvNDRxWSoCwORGX7c_-P4sYa6E3l05Ohw=s240-c-k-c0x00ffffff-no-rj"
            alt="hackmakemod"
          />
        </v-avatar>
      </v-toolbar>
      <!-- <p class="ma-4">
      Which YouTube channel would you like to add to your personal API endpoint?
    </p> -->
      <v-card-title class="primary--text"> Setup your API Feed </v-card-title>
      <v-card-text v-if="admin">
        <v-form
          @submit.prevent="handleSubmit"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div v-if="admin.youtube.active">
            <div class="form-group  mb-6 mt-2">
              <v-text-field
                v-model="form.Youtube"
                placeholder="https://www.youtube.com/channel/OREITUEODILGKXDJ or https://www.youtube.com/c/{channel name}"
                label="Youtube Channel"
                :rules="websiteRules"
                hide-details="auto"
                ><v-icon slot="prepend" color="red">
                  mdi-youtube
                </v-icon></v-text-field
              >
            </div>
            <div
              class="form-group ml-12"
              v-if="admin.youtube.latestVideo.active"
            >
              <v-switch
                prepend-icon="mdi-video-plus"
                v-model="getLatestVideo"
                :loading="gettingLatestVideo"
                inset
                label="Get my latest video statistics"
              ></v-switch>
            </div>
            <v-divider></v-divider>
          </div>
          <div v-if="admin.twitter.active">
            <div class="form-group mb-6 mt-2">
              <v-text-field
                v-model="form.Twitter"
                placeholder="handle or @handle"
                label="Twitter handle"
                hide-details="auto"
                ><v-icon slot="prepend" color="info">
                  mdi-twitter
                </v-icon></v-text-field
              >
            </div>
            <v-divider></v-divider>
          </div>

          <v-row>
            <v-col v-if="admin.stocks.active">
              <v-card class="ml-4 mt-6" elevation="3" max-width="400">
                <v-card-title
                  ><v-icon large color="green darken-2" class="mr-2"
                    >mdi-finance</v-icon
                  >Stocks</v-card-title
                >
                <v-divider></v-divider>
                <v-list flat subheader three-line>
                  <v-subheader class="pa-3 ma-2 mt-4">
                    Add your favorite Stocks to your API profile. The checkbox
                    activates/deactivates the stock, or you may choose to delete
                    it with the trash can.</v-subheader
                  >

                  <v-list-item v-for="(stock, i) in api.stocks" :key="i">
                    <v-checkbox
                      v-model="stock.active"
                      :input-value="stock.active"
                      @change="checkboxChanged(stock)"
                      class="mr-4"
                    ></v-checkbox>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ stock.stock }}
                        <span
                          class="green--text"
                          v-if="stock.price !== 'Disabled'"
                          >{{ formatPrice(stock.price) }}</span
                        >
                        <span class="red--text darken-2" v-else>{{
                          formatPrice(stock.price)
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle v-if="stock.price !== 'Disabled'"
                        >Current trading value.
                        {{ stock.updated }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle v-else
                        >Click the checkbox to enable this stock. The value will
                        update next cycle.</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-btn text @click="removeStockTickerItem(stock)"
                      ><v-icon color="red darken-2">mdi-delete</v-icon></v-btn
                    >
                  </v-list-item>
                </v-list>
                <v-card-actions
                  ><p
                    v-if="this.api.stocks.length == 4"
                    class="red--text darken-2"
                  >
                    Limit: 4 Stocks per account.
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary accent-4"
                    @click="reveal = true"
                    :disabled="this.api.stocks.length == 4"
                  >
                    Add a stock ticker
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <v-card
                    v-if="reveal"
                    class="transition-fast-in-fast-out v-card--reveal"
                    style="height: 100%"
                  >
                    <v-card-text class="pb-0">
                      <p class="text-h5 text--primary">Enter a Stock Symbol</p>
                      <p
                        class="red--text darken-2 pa-0 ma-0"
                        style="margin-top: -15px !important"
                      >
                        {{ errorTxt }}
                      </p>
                      <v-form>
                        <div class="form-group mb-4 mt-6">
                          <v-text-field
                            class="text-capitalize"
                            v-model="stockSymbolText"
                            autofocus
                            @keyup="uppercase"
                            placeholder="ie GOOGL"
                            label="Ticker Symbol"
                            hide-details="auto"
                            ><v-icon slot="prepend" color="green darken-2">
                              mdi-finance
                            </v-icon></v-text-field
                          >
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-btn
                        text
                        color="primary accent-4"
                        @click="
                          reveal = false;
                          stockSymbolText = '';
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary accent-4" @click="addStock">
                        Add
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col v-if="admin.weather.active">
              <h3 class="primary--text mt-4">Other Data points:</h3>
              <p>
                Get local weather based on your current GPS location.
                <!-- <span v-if="api.weather"
                  ><strong
                    >Lat: {{ api.weather.lat }}, Long:
                    {{ api.weather.long }}</strong
                  ></span
                > -->
              </p>
              <div class="form-group ml-6">
                <v-switch
                  inset
                  prepend-icon="mdi-weather-partly-cloudy"
                  :loading="gettingWeather"
                  color="info"
                  v-model="includeWeather"
                  label="Include my local weather"
                ></v-switch>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-btn
        block
        depressed
        dark
        color="primary lighten-2"
        @click="saveForm"
        :loading="saving"
        :disabled="!valid"
        >Preview and Save Changes</v-btn
      >

      <!-- <v-btn block depressed class="mt-2" color="light" @click="clearForm"
        >Clear</v-btn
      > -->
    </v-card>
    <v-list
      dense
      v-if="api"
      elevation="2"
      class="pa-6 mt-2"
    >
      <h3 class="primary--text">Your API Profile:</h3>
      <v-list-item three-line outlined v-if="api.youtube" class="mt-2 mb-2">
        <v-avatar color="primary" rounded size="80" left class="mr-3" top
          ><v-img :src="api.youtube.thumbnail"></v-img
        ></v-avatar>
        <v-list-item-content>
          <v-list-item-title
            >Channel: {{ api.youtube.channel }}</v-list-item-title
          >
          <v-list-item-subtitle
            >Subscribers: <strong>{{ api.youtube.subscribers }}</strong> Views:
            <strong>{{ api.youtube.views }}</strong> Videos:
            <strong>{{ api.youtube.videos }}</strong></v-list-item-subtitle
          >
          <p class="red--text">Youtube</p>

          <v-list v-if="getLatestVideo && api.youtube.latestVideo">
            <v-list-group :value="true" sub-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ api.youtube.channel }}'s Latest
                    Video:</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item three-line outline v-if="!saving">
                <v-avatar color="primary" rounded size="80" left class="mr-3">
                  <v-img :src="api.youtube.latestVideo.thumbnail"></v-img>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >Title: {{ api.youtube.latestVideo.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    ><p>Date: {{ api.youtube.latestVideo.published }}</p>
                    Views:
                    <strong>{{
                      api.youtube.latestVideo.statistics.viewCount
                    }}</strong>
                    Likes:
                    <strong>{{
                      api.youtube.latestVideo.statistics.likeCount
                    }}</strong>
                    Comments:
                    <strong>{{
                      api.youtube.latestVideo.statistics.commentCount
                    }}</strong></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <div class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-list-item-content>
      </v-list-item>

      <v-list-item three-line outlined v-if="api.twitter" class="mt-5">
        <v-avatar class="blue lighten-3 mr-3" rounded size="80" left
          ><v-img :src="api.twitter.thumbnail"></v-img
        ></v-avatar>
        <v-list-item-content>
          <v-list-item-title
            >Handle: @{{ api.twitter.accountName }} Name:
            {{ api.twitter.name }}</v-list-item-title
          >
          <v-list-item-subtitle
            >Followers: <strong>{{ api.twitter.followers }}</strong> Friends:
            <strong>{{ api.twitter.friends }}</strong> Tweets:
            <strong>{{ api.twitter.tweets }}</strong></v-list-item-subtitle
          >
          <p class="info--text">Twitter</p>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        three-line
        outlined
        v-if="filteredStocks.length"
        class="mt-5"
      >
        <v-avatar class="blue lighten-3 mr-3" rounded size="80" left
          ><v-icon large>mdi-finance</v-icon></v-avatar
        >
        <v-list-item-content>
          <v-list>
            <v-list-item v-for="(stock, i) in filteredStocks" :key="i">
              <v-list-item-content>
                <v-list-item-title>
                  {{ stock.stock }}
                  <span class="green--text">{{
                    formatPrice(stock.price)
                  }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-item-content>
      </v-list-item>

      <v-list-item three-line outlined v-if="api.weather" class="mt-5">
        <v-avatar class="blue lighten-3 mr-3" rounded size="80" left
          ><v-img
            v-if="api.weather.forecast"
            :src="api.weather.forecast.now.icon"
          ></v-img
        ></v-avatar>
        <v-list-item-content>
          <v-list-item-title
            >{{ api.weather.forecast.now.shortForecast }}
            {{ api.weather.forecast.now.temperature }}
            {{ api.weather.forecast.now.temperatureUnit }}
          </v-list-item-title>
          <v-list-item-subtitle
            >{{ api.weather.forecast.later.name }}:
            {{
              api.weather.forecast.later.detailedForecast
            }}</v-list-item-subtitle
          >
          <p class="info--text">Weather</p>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="showCodeGenerator = true"
        color="primary darken-2"
        dark
        block
        class="mt-4"
        >Generate Code for my project!</v-btn
      >
    </v-list>
    <v-dialog
      v-model="showCodeGenerator"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <code-generator v-on:close-dialog="closeCodeGenerator"></code-generator>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
const axios = require("axios");
const moment = require("moment");

export default {
  name: "Home",
  data: () => ({
    api: null,
    showCodeGenerator: false,
    saving: false,
    reveal: false,
    stocks: [],
    weather: {},
    errorTxt: "",
    selectedStocks: [],
    getLatestVideo: false,
    gettingLatestVideo: false,
    stockSymbolText: "",
    includeWeather: false,
    gettingWeather: false,
    getLocation: true,
    admin: null,
    form: {
      Youtube: null,
      Twitter: null,
      Facebook: null,
      Instagram: null,
      TikTok: null,
    },
    websiteRules: [
      (value) => {
        const pattern =
          /^(https?:\/\/)?(www\.)?(youtube.com\/channel\/[a-z0-9]+)|(https?:\/\/)?(www\.)?(youtube.com\/c\/[a-z0-9]+)/gim; /* eslint-disable-line */
        return (
          pattern.test(value) ||
          "Enter the link to your youtube channel: ie https://www.youtube.com/channel/EWOIRUWEOIFUSODJHDL or https://www.youtube.com/c/channel"
        );
      },
    ],
    valid: false,
  }),
  components: {},
  computed: {
    ...mapGetters(["getUser"]),
    filteredStocks() {
      if(!this.api.stocks){
        return [];
      }
      return this.api.stocks.filter((stock) => {
        return stock.active;
      });
    },
  },
  watch: {
    saving(val) {
      this.saving = val;
      this.$nextTick();
    },
    async selectedStocks(val) {
      this.selectedStocks = val;
      this.$nextTick();
    },
    async includeWeather(val) {
      this.includeWeather = val;
      await this.updateProjectSettings({
        "settings.weather.includeWeather": val,
      });
      if (val == true) {
        await this.geoFindMe();
      } else {
        this.removeWeather();
      }
      this.$nextTick();
    },
    async getLocation(val) {
      this.getLocation = val;
      this.$nextTick();
    },
    async getLatestVideo(val) {
      this.getLatestVideo = val;

      await this.updateProjectSettings({
        "settings.youtube.getLatestVideo": val,
      });

      if (!val) {
        this.gettingLatestVideo = true;
        await this.removeLatestVideo();
        this.gettingLatestVideo = false;
      }
      this.$nextTick();
    },
    form: {
      deep: true,
      handler(val) {
        this.form.Youtube = val.Youtube;
        this.$nextTick();
      },
    },
    api(val) {
      if (val !== {}) {
        this.api = val;
        this.$nextTick();
      } else {
        this.api = null;
      }
    },
  },
  methods: {
    async checkboxChanged() {
      var store = firebase.firestore();
      const stocks = this.api.stocks.map((stock) => {
        return {
          stock: stock.stock,
          active: stock.active,
        };
      });
      await store.collection("projects").doc(this.getUser.uid).update({
        "api.stocks": this.api.stocks,
        Stocks: stocks,
      });
    },
    async restoreStocks() {
      var store = firebase.firestore();
      let stocks = [];
      if (!this.stocks.length && this.api.stocks.length) {
        await store.collection("projects").doc(this.getUser.uid).update({
          "api.stocks": [],
        });
      }
      for (let i = 0; i < this.stocks.length; i++) {
        const stock = this.stocks[i];
        try {
          var config = {
            method: "get",
            url: `${process.env.VUE_APP_API_BASE_URL}/v1/stocks/${stock.stock}`,
          };
          const response = await axios(config);
          response.data.active = stock.active;
          stocks.push(response.data);

          await store.collection("projects").doc(this.getUser.uid).update({
            "api.stocks": stocks,
          });
        } catch (err) {
          return console.log(err);
        }
      }
    },
    async addStock() {
      this.errorTxt = "";

      if (this.api.stocks.length < 4) {
        try {
          var config = {
            method: "get",
            url: `${process.env.VUE_APP_API_BASE_URL}/v1/stocks/${this.stockSymbolText}`,
          };
          const response = await axios(config);
          if (!response.data.error) {
            response.data.active = true;
            this.api.stocks.push(response.data);
            this.stocks.push({
              stock: response.data.stock,
              active: true,
            });
            var store = firebase.firestore();
            await store.collection("projects").doc(this.getUser.uid).update({
              "api.stocks": this.api.stocks,
              Stocks: this.stocks,
            });
            this.reveal = false;
            this.stockSymbolText = "";
          } else {
            throw response.data;
          }
        } catch (err) {
          console.log(err.message);
          if (err.message.includes("not found")) {
            this.errorTxt = `Symbol: ${this.stockSymbolText} is not found!`;
          }
        }
      }
      console.log(this.stockSymbolText);
    },
    formatPrice(value) {
      if (value === "Disabled") {
        return value;
      }
      return (
        "$" +
        value.toString().replace(/^\$(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/gim)
      );
    },
    formatDate(date) {
      return moment(date).format("H:MM a");
    },
    uppercase() {
      this.stockSymbolText = this.stockSymbolText.toUpperCase();
    },
    async removeWeather() {
      this.saving = true;
      this.getLocation = false;
      var store = firebase.firestore();
      await store
        .collection("projects")
        .doc(this.getUser.uid)
        .update({ ["api.weather"]: firebase.firestore.FieldValue.delete() });
      this.saving = false;
    },
    async removeLatestVideo() {
      this.saving = true;
      this.getLocation = false;
      var store = firebase.firestore();
      await store
        .collection("projects")
        .doc(this.getUser.uid)
        .update({
          ["api.youtube.latestVideo"]: firebase.firestore.FieldValue.delete(),
        });
      this.saving = false;
    },
    async removeStockTickerItem(stock) {
      this.saving = true;

      var store = firebase.firestore();
      await store
        .collection("projects")
        .doc(this.getUser.uid)
        .update({
          [`Stocks`]: firebase.firestore.FieldValue.arrayRemove({
            stock: stock.stock,
            active: stock.active,
          }),
          ["api.stocks"]: firebase.firestore.FieldValue.arrayRemove(stock),
        });
      // await this.restoreStocks();
      this.saving = false;
    },
    async updateProjectSettings(setting) {
      this.saving = true;
      var store = firebase.firestore();
      await store.collection("projects").doc(this.getUser.uid).update(setting);
      this.saving = false;
    },
    clearForm() {
      this.form.Youtube = null;
      this.form.Twitter = null;
      this.form.Facebook = null;
      this.form.Instagram = null;
      this.form.TikTok = null;
      this.includeWeather = false;
      this.getLatestVideo = false;
      this.api = null;
    },
    closeCodeGenerator() {
      this.showCodeGenerator = false;
    },
    async geoFindMe() {
      this.gettingWeather = true;
      const user = this.getUser;
      console.log("Getting the weather!");
      try {
        if (!navigator.geolocation) {
          console.log("Geolocation is not supported by your browser");
        } else {
          console.log("Locating…");
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              console.log(latitude, longitude);
              var config = {
                method: "get",
                url: `${process.env.VUE_APP_API_BASE_URL}/v1/weather/${latitude}/${longitude}`,
              };
              const response = await axios(config);
              if (response && !response.data.name == 'Error') {
                this.gettingWeather = false;
                console.log(response.data);
                var store = firebase.firestore();
                await store
                  .collection("projects")
                  .doc(user.uid)
                  .update({
                    Weather: {
                      lat: latitude,
                      long: longitude,
                    },
                    "api.weather": {
                      lat: latitude,
                      long: longitude,
                    },
                    "api.weather.forecast": response.data,
                  });
              } else {
                var store = firebase.firestore();
                await store
                  .collection("projects")
                  .doc(user.uid)
                  .update({
                    Weather: {
                      lat: latitude,
                      long: longitude,
                    },
                    "api.weather": {
                      lat: latitude,
                      long: longitude,
                    },
                    "api.weather.forecast": {},
                  });
              }
            },
            function () {
              console.log("Unable to retrieve your location");
            }
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    async updateAPI() {

      var config = {
        method: "get",
        url: `${process.env.VUE_APP_API_BASE_URL}/v1/feed/${this.getUser.displayName}`,
      };

      // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      //create helper for more api calls.

      const response = await axios(config);
      if (response) {
        this.gettingLatestVideo = false;
        return response.data;
      }
    },
    async saveForm() {
      if (this.$refs.form.validate()) {
        var store = firebase.firestore();
        const { Youtube, Twitter, Facebook, Instagram, TikTok } = this.form;

        await store.collection("projects").doc(this.getUser.uid).update({
          user: this.getUser.uid,
          Youtube,
          Twitter,
          Facebook,
          Instagram,
          TikTok,
          Stocks: this.stocks ? this.stocks : [],
          Weather: this.weather ? this.weather : {},
          updated: firebase.firestore.FieldValue.serverTimestamp(),
          api: null,
        });
        this.saving = true;
        await this.updateAPI();
        this.saving = false;
      }
    },
  },
  async mounted() {
    this.updateAPI();
  },
  async created() {
    var store = firebase.firestore();

    const features = await store.collection('administration');
    
     features.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
         if (change.type == "modified" || change.type == "added") {
           let doc = change.doc;
           this.admin = doc.data()
         }
      })
     })


    let ref = store
      .collection("projects")
      .where("user", "==", this.getUser.uid);

    ref.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        // if (change.type == 'added') {}

        if (change.type == "modified" || change.type == "added") {
          let doc = change.doc;
          let data = await doc.data();
          this.form.Youtube = data.Youtube;
          this.form.Twitter = data.Twitter;
          this.form.Facebook = data.Facebook;
          this.form.Instagram = data.Instagram;
          this.form.TikTok = data.TikTok;
          this.stocks = data.Stocks;
          this.weather = data.Weather;
          this.api = data.api;
          this.getLatestVideo = data.settings.youtube.getLatestVideo;
          this.includeWeather = data.settings.weather.includeWeather;
          //
        }
        // if (change.type == 'removed') {}
      });
    });
  },
};
</script>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>